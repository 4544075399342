import React from 'react';
import '../Page.scss';

const PrivacyPolicyPage: React.FC = () => (
  <div className='page'>
    <h3>Ova Politika privatnosti opisuje naše politike i procedure o prikupljanju, korišćenju i otkrivanju vaših ličnih podataka, kada koristite našu Uslugu i obaveštava vas o vašem pravu na privatnost i o tome kako vas zakon štiti.
        Koristimo vaše lične podatke u svrhu pružanja i poboljšavanja naše Usluge. Koristeći našu Uslugu, pristajete na prikupljanje i upotrebu vaših ličnih podataka, u skladu sa ovom Politikom privatnosti.
    </h3>

    <h1>TUMAČENJE I DEFINICIJE</h1>

    <h2>TUMAČENJE</h2>
    <h4>Reči čije je početno slovo napisano velikim slovom imaju definisana značenja pod sledećim uslovima. 
      Sledeće definicije će imati isto značenje bez obzira pojavljuju li se u jednini ili u množini, u muškom ili ženskom rodu.
    </h4>

    <h2>DEFINICIJE</h2>
    <h3>• Račun je jedinstveni račun kreiran za pristup našoj Usluzi ili delovima naše Usluge.</h3>
    <h3>• Aplikacija je softverski program pod imenom eNovčanik, razvijen od strane kompanije, a preuzet od strane vas.</h3>
    <h3>• Država je Republika Srbija.</h3>
    <h3>• Uređaj je bilo koji uređaj koji može pristupiti Usluzi, poput mobilnog telefona ili tableta.</h3>
    <h3>• Lični podaci su sve informacije koje se odnose na identifikovanog pojedinca ili pojedinca koji se može identifikovati.</h3>
    <h3>• Za potrebe ZZPL-a, lični podaci označavaju sve informacije koje se odnose na vas, kao što su ime, identifikacioni broj, 
      podaci o lokaciji, online identifikator ili na jedan ili više faktora specifičnih za fizički, fiziološki, genetski, mentalni, 
      ekonomski, kulturni ili društveni identitet.
    </h3>
    <h4>• Usluga se odnosi na Aplikaciju.</h4>
    <h4>• Pružalac Usluge je svako fizičko ili pravno lice koje obrađuje podatke u ime Aplikacije. 
      Odnosi se na eksterno angažovane kompanije ili pojedince radi omogućavanja Usluge, pružanja Usluge obavljanja aktivnosti 
      u vezi sa Uslugom ili pomaganja u analizi načina na koji se Usluga koristi.
    </h4>
    <h4>• Podaci o upotrebi su podaci prikupljeni automatski, bilo da su generisani korišćenjem Usluge ili prikupljeni iz same infrastrukture Usluge.</h4>
    <h4>• Država je Republika Srbija.</h4>
    <h4>• Uređaj je bilo koji uređaj koji može pristupiti Usluzi, poput mobilnog telefona ili tableta.</h4>
    <h4>• Lični podaci su sve informacije koje se odnose na identifikovanog pojedinca ili pojedinca koji se može identifikovati.</h4>
    <h4>• Za potrebe ZZPL-a, lični podaci označavaju sve informacije koje se odnose na vas, kao što su ime, identifikacioni broj, 
      podaci o lokaciji, online identifikator ili na jedan ili više faktora specifičnih za fizički, fiziološki, genetski, mentalni, 
      ekonomski, kulturni ili društveni identitet.
    </h4>
    <h4>• Usluga se odnosi na Aplikaciju.</h4>
    <h4>• Pružalac Usluge je svako fizičko ili pravno lice koje obrađuje podatke u ime Aplikacije. Odnosi se na eksterno angažovane
       kompanije ili pojedince radi omogućavanja Usluge, pružanja Usluge obavljanja aktivnosti u vezi sa Uslugom ili pomaganja u 
       analizi načina na koji se Usluga koristi.
    </h4>
    <h4>• Lični podaci su sve informacije koje se odnose na identifikovanog pojedinca ili pojedinca koji se može identifikovati.</h4>
    <h4>• Za potrebe ZZPL-a, lični podaci označavaju sve informacije koje se odnose na vas, kao što su ime, identifikacioni broj, 
      podaci o lokaciji, online identifikator ili na jedan ili više faktora specifičnih za fizički, fiziološki, genetski, mentalni, 
      ekonomski, kulturni ili društveni identitet.
    </h4>
    <h4>• Usluga se odnosi na Aplikaciju.</h4>
    <h4>• Pružalac Usluge je svako fizičko ili pravno lice koje obrađuje podatke u ime Aplikacije. Odnosi se na eksterno angažovane 
      kompanije ili pojedince radi omogućavanja Usluge, pružanja Usluge obavljanja aktivnosti u vezi sa Uslugom ili pomaganja u analizi 
      načina na koji se Usluga koristi.
    </h4>
    <h4>• Podaci o upotrebi su podaci prikupljeni automatski, bilo da su generisani korišćenjem Usluge ili prikupljeni iz 
      same infrastrukture Usluge.
    </h4>
  </div>
);

export default PrivacyPolicyPage;