import React from 'react';
import './LandingPage.scss';

const LandingPage: React.FC = () => {

  console.log('I render LandingPage');

  return (
    <div className="landing-page">
        <div className="download-links">
            <a href="https://apps.apple.com/rs/app/enov%C4%8Danik/id1662199902">
              <img className="logo" src={process.env.PUBLIC_URL + '/appleStoreBadge.png'} alt="AppStoreLink"/>
            </a>
            <a>
              <img className="logo" src={process.env.PUBLIC_URL + '/googleStoreBadge.png'} alt="PlayStoreLink"/>
            </a>
        </div>
        <div className="app-screenshots">
          <img className="logo" src={process.env.PUBLIC_URL + '/iOS-pic-1.png'} alt="iOS-pic-1"/>
          <img className="logo" src={process.env.PUBLIC_URL + '/iOS-pic-2.png'} alt="iOS-pic-2"/>
          <img className="logo" src={process.env.PUBLIC_URL + '/iOS-pic-3.png'} alt="iOS-pic-3"/>
        </div>
    </div>
  )
};

export default LandingPage;