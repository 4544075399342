import React from 'react';
import './Navbar.scss';

interface Props {
  selectedPage: string;
  setSelectedPage: React.Dispatch<React.SetStateAction<string>>;
}

const Navbar: React.FC<Props> = ({ selectedPage, setSelectedPage }) => {

  console.log('I render Navbar 😁');

  return (
    <nav className="navbar">
      <div className='logo-container'>
        <a className={`nav-item nav-link ${selectedPage === 'LandingPage' ? 'active' : ''}`} href="/" onClick={() => setSelectedPage('LandingPage')}>
          <img className="logo" src={process.env.PUBLIC_URL + '/eNovcanik.png'} alt="Logo" />
        </a>
      </div>
      <div className='links-container'>
        <a className={`nav-item nav-link ${selectedPage === 'LandingPage' ? 'active' : ''}`} href="/" onClick={() => setSelectedPage('LandingPage')}>Početna</a>
        <a className={`nav-item nav-link ${selectedPage === 'PrivacyPolicy' ? 'active' : ''}`} href="/privacy-policy" onClick={() => setSelectedPage('PrivacyPolicy')}>Politika privatnosti</a>
        <a className={`nav-item nav-link ${selectedPage === 'TermsAndConditions' ? 'active' : ''}`} href="/terms-conditions" onClick={() => setSelectedPage('TermsAndConditions')}>Uslovi korišćenja</a>
      </div>
    </nav>
  );
};

export default Navbar;