import React from 'react';
import '../Page.scss';

const TermsAndConditionsPage: React.FC = () => (
  <div className='page'>
    <h3>
      Ovi Uslovi korišćenja (u daljem tekstu Uslovi), sa svim potencijalnim izmenama, odnose se na korišćenje eNovčanik 
      mobilne aplikacije (u daljnjem tekstu: Aplikacija), softvera kreiranog za olakšavanje praćenja troškova i prihoda putem 
      mobilnog telefona. Pristupom, otvaranjem naloga ili korišćenjem Aplikacije potvrđujete i slažete se da ste pročitali, 
      razumeli i složili se sa dolenavedenim uslovima i odredbama. Sve izmene Uslova će biti pravovremeno objavljene na sajtu 
      servisa, sa primerenim rokom stupanja na snagu od dana objavljivanja, te je Korisnik dužan da proverava i da bude upoznat 
      sa svim izmjenama Uslova.
    </h3>

    <h1>DEFINICIJE</h1>

    <h3>
      “Aplikacija” podrazumijeva izvorna softverska rešenja kreirana za olakšavanje praćenja troškova putem mobilnog telefona, 
      prilagođena za Apple i Android prenosive uređaje. “Korisnik” ili „Korisnici“ je pravno ili fizičko lice koje ispunjava 
      kriterijume predviđene ovim Uslovima korišćenja, koji je pročitao i razumeo Uslove i sa njima se u potpunosti složio 
      prilikom registracije.
    </h3>

    <h1>OPSEG I VRSTA USLUGA</h1>

    <h3>
      Aplikacija je deo softvera kreiran za olakšavanje praćenja troškova i prihoda putem mobilnog telefona. Aplikacija se 
      preuzima sa App Store-a za korisnike iOS operativnog sistema, odnosno sa Google Play store-a za korisnike Android 
      operativnog Sistema. Preuzimanjem i instalacijom aplikacije, Korisnik se obavezuje da sadržaj Aplikacije koristi isključivo 
      za ličnu upotrebu i na sopstvenu odgovornost.
    </h3>

    <h1>OBIM PRAVA</h1>

    <h3>
      Korisnik koristi Aplikaciju isključivo na svoj rizik. Korišćenjem sadržaja Aplikacije korisnik prihvata sve rizike koji 
      mogu da nastanu iz njegovog korišćenja. Korisnik ne sme deliti ili učiniti Aplikaciju dostupnom trećim stranama (osim u 
      meri u kojoj to dopuštaju Uslovi), bez prethodnog pristanka, prodavati, iznajmljivati, posuđivati ili na drugi način 
      distribuirati aplikaciju. Korisnik se obavezuje da će učiniti sve što je u njegovoj moći da bilo koje treće lice ne dođe 
      u posed uređaja na kome je Aplikacija instalirana, kao i da nikome neće omogućiti neovlašćeno upoznavanje sa njegovim 
      ličnim nalogom na mobilnim aplikacijama. Korisnik je saglasan da neće koristiti obrnuti inženjering, povratnu montažu, 
      rastavljanje, dekodiranje ili bilo koji drugi način za otkrivanje izvornog koda aplikacije ili bilo koje povezane 
      tehnologije. Kršenja gore navedenih obaveza, kao i pokušaj takvog kršenja, mogu biti predmet krivičnog gonjenja i štete.
      Sva prava koja nisu izričito data su zadržana.
    </h3>

    <h1>ZAŠTITA PODATAKA</h1>

    <h3>
      Za korišćenje funkcionalnosti aplikacije, potrebna je prethodna registracija od strane Korisnika. Obrada podataka Korisnika 
      za funkcionalnost same aplikacije obavlja se na osnovu pristanka.cKorisniku se nakon instaliranja mobilne aplikacije, u toku 
      procesa kreiranja korisničkog naloga, prikazuju Uslovi korišćenja i Politika privatnosti, i Korisnik je dužan da ih pročita, 
      razume i da se sa njima saglasi, kao i da, pre početka korišćenja aplikacije, ukoliko je saglasan, dâ svoju saglasnost, kao 
      i da dâ svoju saglasnost za obradu podataka o ličnosti koje unosi u aplikaciju. Unoseći svoje lične podatke u aplikaciju, 
      Korisnik pristaje na obradu ličnih podataka u skladu sa Politikom privatnosti i ovim Uslovima. Korisnik se pristupom, otvaranjem 
      naloga i korišćenjem Aplikacije izričito saglašava sa tim da Aplikacija prikuplja, čuva i obrađuje prikupljene podatke, radi 
      preciznijeg informisanja samog korisnika o statistici potrošnje, u svrhe marketinških i prodajnih aktivnosti, u svrhe korisničke 
      podrške, kao i u svrhe segmentacije Korisnika usluga, istraživanja i analize tržišta. Prihvatanjem Uslova korišćenja, Korisnik 
      daje saglasnost za primanje obaveštenja (notifikacija). Data saglasnost iz prethodnog stava može se u bilo koje doba opozvati. 
      Možete iskoristiti otkazivanja i prigovaranja, kontaktirajući nas. Imajte na umu da ćemo od vas možda tražiti da potvrdite svoj 
      identitet pre nego što odgovorimo na takve zahteve.
    </h3>

    <h1>ISKLJUČENJE ODGOVORNOSTI</h1>

    <h3>
      Predstavnici partnerskih kompanija, licencirani predstavnici, agenti ili druga lica koja učestvuju u stvaranju, izradi, 
      promociji ili koja na drugi način čine Aplikaciju i sadržaj aplikacije dostupnim, nisu materijalno odgovorni za bilo 
      kakvu kaznenu, posebnu, indirektnu ili posledičnu štetu koja na bilo koji način može nastati korišćenjem Aplikacije, 
      ili je na bilo koji način u vezi s korišćenjem ove Aplikacije, za bilo koje radnje korisnika upotrebom ili zloupotrebom 
      sadržaja Aplikacije, te za bilo kakvu štetu koja može biti prouzrokovana korisniku ili bilo kojoj trećoj strani u vezi 
      s upotrebom ili zloupotrebom korišćenja sadržaja Aplikacije. Ne garantujemo, te ne snosimo nikakvu vrstu odgovornosti 
      za sledeće situacije:
    </h3>
    <h3>
      • da prilikom korišćenja Aplikacije neće biti grešaka i/ili da će ista funkcionisati bez prekida u svakom trenutku
    </h3>
    <h3>
      • Ukoliko je došlo do zloupotrebe Aplikacije od strane trećih lica.
    </h3>
    <h3>
      Za prikupljanje podataka sa fiskalnih računa Aplikacija se oslanja na servise koji su javno dostupni i ne može odgovarati 
      za verodostojnost i kvalitet podataka.
    </h3>

    <h1>IZMENE OVIH USLOVA KORIŠĆENJA</h1>

    <h3>
      S vremena na vreme, možemo ažurirati naše Uslove korišćenja. Obavestićemo vas o bilo kojoj promeni objavljivanjem novih 
      Uslova korišćenja na ovoj stranici. Prethodno ćemo vas obavestiti putem e-pošte i/ili istaknutog obaveštenja u okviru naše 
      Usluge da promena stupa na snagu i ažuriraćemo datum. Savetujemo vam da povremeno pregledate ove Uslove korišćenja radi 
      izmena. Izmene ovih Uslova korišćenja stupaju na snagu datumom objavljivanja.
    </h3>

    <h1>KONTAKTIRAJTE NAS</h1>

    <h3>
      Ako imate pitanja o ovoj Politici privatnosti, možete nas kontaktirati e-poštom: enovcanikapp@gmail.com
    </h3>
  </div>
);

export default TermsAndConditionsPage;
