import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from './LandingPage/LandingPage';
import PrivacyPolicyPage from './PrivacyPolicyPage/PrivacyPolicyPage';
import TermsAndConditionsPage from './TermsAndConditionsPage/TermsAndConditionsPage';

const RouterComponent: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-conditions" element={<TermsAndConditionsPage />} />
    </Routes>
  </Router>
);

export default RouterComponent;
