import React, { useState } from 'react';
import NavbarComponent from '../Navbar/Navbar';
import RouterComponent from '../Router';

const Home: React.FC = () => {
  const [selectedPage, setSelectedPage] = useState('LandingPage');

  console.log('I render Home 😁');

  return (
    <>
      <NavbarComponent selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      <RouterComponent />
    </>
  );
};

export default Home;
